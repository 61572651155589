import React, { useState, useRef } from 'react'
import 'aframe'
import { useEffect } from 'react'
import './App.css'
import Logo from './logo.svg'

const App = () => {
  const videoRef = useRef(null)
  const cameraRef = useRef(null)
  const starterRef = useRef(null)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    starterRef.current.addEventListener('click', () => {
      if (!ready) return
      starterRef.current.style.display = 'none'
      videoRef.currentTime = 0
      videoRef.current.play()
    })
  }, [ready])

  useEffect(() => {
    videoRef.current.addEventListener('canplaythrough', () => {
      setReady(true)
    })
    setTimeout(() => {
      setReady(true)
    }, 5000)
  }, [])

  return (
    <a-scene>
      <a-videosphere
        rotation='0 180 0'
        src='#video'
        play-on-window-click
        play-on-vrdisplayactivate-or-enter-vr
      ></a-videosphere>

      <a-camera
        user-height='0'
        wasd-controls-enabled='false'
        arrow-key-rotation
        ref={cameraRef}
      ></a-camera>
      <div className='starter' ref={starterRef}>
        <img src={Logo} alt='logo' className='logo' />

        {ready ? 'Click here to start the video' : 'Loading video, please wait'}
      </div>

      <a-assets>
        <video
          id='video'
          style={{ display: 'none' }}
          autoPlay
          loop
          crossOrigin='anonymous'
          preload='auto'
          muted
          playsInline
          webkit-playsinline='true'
          ref={videoRef}
        >
          {/* <source
            type='video/mp4'
            src='https://johannes-cdn.s3.eu-south-1.amazonaws.com/dev/video.mp4'
          /> */}
          <source
            type='video/mp4'
            src='https://cdn.anomaleet.com/videos/rome.mp4'
          />
          {/* <source
            type='application/x-mpegURL'
            src='https://customer-5mhevb91mj8tpl1k.cloudflarestream.com/6e91de584d2a8c0d17cc7aca337f89c7/manifest/video.m3u8'
          /> */}
        </video>
      </a-assets>
    </a-scene>
  )
}

export default App
